let data = [
  {
    path: "/component/button",
    component: () => import("@/pages/ComponentStructure/button.vue"),
    meta: {
      layout: "home",
    },
  },
  {
    path: "/component/icons",
    component: () => import("@/pages/ComponentStructure/button.vue"),
    meta: {
      layout: "home",
    },
  },
  {
    path: "/component/inputs",
    component: () => import("@/pages/ComponentStructure/button.vue"),
    meta: {
      layout: "home",
    },
  },
  {
    path: "/component/checkbox",
    component: () => import("@/pages/ComponentStructure/button.vue"),
    meta: {
      layout: "home",
    },
  },
];


export default data;
