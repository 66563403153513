import axios from "axios";
import router from "@/router";

axios.interceptors.request.use((config) => {
  let token = JSON.parse(localStorage.getItem("token"));
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});
axios.defaults.baseURL = "https://etenderapp.techvoyager.in/api/";
axios.interceptors.response.use(
  function (response) {
    if (response) {
      if (response.status === 401) {
        return router.push("/signin/buyer");
      } else if (response.status === 403) {
        return router.push(response.data.route);
      }
    }
    return response;
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === 401) {
        return router.push("/signin/buyer");
      } else if (error.response.status === 403) {
        return router.push(error.response.data.route);
      }
    }
  }
);

export default axios;
