<template>
  <div class="login-image position-relative d-flex align-items-center justify-content-center">
    <div class="company-deets ">
      <a class="  text-start  navbar-brand p-0 " href="#"> eTender </a>
      <div class="text-end text-white " style="font-size:14px">
        All Rights Reserved. Powered by “eTender”
      </div> 
    </div>
    <div class="width-fix-login  d-flex align-items-center justify-content-between ">
      <div
        class="card card-body d-flex align-items-center min-vh-80 "
      >
        <div class="row my-auto d-flex align-items-center justify-content-between  " style="padding: 0  0 0 4.87rem">
          <div class="col-lg-7 col-12 pe-5 " style="">
            <div
              id="carouselExampleCaptions"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true" 
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleCaptions"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  
                  <img
                    src="@\assets\images\loginpic.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="@\assets\images\loginpic.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div class="carousel-item">
                  <img
                    src="@\assets\images\loginpic.png"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-5 col-12 d-flex  justify-content-end px-5  " style="">
            <div
            class="card card-body  p-0 " id="form-login"
            >
              <slot />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {},
  methods: {},
};
</script>

<style>
:root {
  --et-bgbody: #eceff3;
  --et-sidebg: #040849;
  --et-sidewidth: 21vw;
  --et-accent: #645df6;
  --et-sidehov: rgba(255, 255, 255, 0.12);
  --et-sideicon: 2rem;
}

:root {
  --et-bgbody: #eceff3;
  --et-sidebg: #040849;
  --et-sidewidth: 21vw;
  --et-accent: #645df6;
  --et-sidehov: rgba(255, 255, 255, 0.12);
  --et-sideicon: 2rem;
}

.btn-outline-accent {
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;

  --bs-btn-color: var(--et-accent);
  --bs-btn-border-color: var(--et-accent);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--et-accent);
  --bs-btn-hover-border-color: var(--et-accent);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--et-accent);
  --bs-btn-active-border-color: var(--et-accent);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--et-accent);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--et-accent);
  --bs-gradient: none;
}

/* input:focus,
button:focus,
select:focus {
  box-shadow: none !important;
  border: 1px solid var(--et-accent) !important;
} */

.btn-accent {
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--et-accent);
  --bs-btn-border-color: var(--et-accent);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #453ce9;
  --bs-btn-hover-border-color: #453ce9;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #453ce9;
  --bs-btn-active-border-color: #453ce9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--et-accent);
  --bs-btn-disabled-border-color: var(--et-accent);
}

.text-accent {
  color: var(--et-accent) !important;
}

.nav-search {
  transform: scale(0.85);
  border: 1px solid lightgray;
}

.nav-search .btn {
  border-radius: 0;
  border-left: 1px solid lightgray;
}

.nav-search .btn:hover {
  box-shadow: none;
}

.account-badge {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-badge .circle {
  width: 2rem;
  margin-left: 1rem;
  outline: 1px solid green;
  outline-offset: 3px;
  height: 2rem;
  background-color: #d9531e;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-items: center;
  color: white;
}

.sidebar {
  height: 100vh;
  z-index: 10000 !important;
  position: fixed;
  top: 0;
  background-color: var(--et-sidebg);
  width: var(--et-sidewidth);
  color: white;

  display: flex;
  flex-direction: column;
}

.btnclose {
  position: absolute;
  top: 1.5rem;
  border-radius: 50%;
  height: 2.5rem;
  z-index: 10000 !important;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--et-accent);
  left: calc(var(--et-sidewidth) - 1.25rem);
  border: 5px solid var(--et-bgbody);
  color: white;
}

.btnclose:hover,
.btnclose:focus {
  background-color: var(--et-sidebg);
  border: 5px solid var(--et-bgbody);
  color: white;
}

.sidebar .brand {
  margin: 0.5rem;
  padding: 0.25rem;
  padding-bottom: 1.5rem;
  text-align: center;
  border-radius: 10px;
  background-color: var(--et-sidehov);
}

.sidebar .brand .title {
  background-color: var(--et-sidehov);

  /* Create the gradient. */
  background-image: linear-gradient(#ffffff, #ffffff, #040749);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  font-weight: 700;
  font-size: 2rem;
  /* line-height: 2.5rem; */
}

.sidebar .brand .small {
  line-height: 0rem;
}

.sidebar .side-ul {
  margin-top: 4rem;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar ul li {
  /* display: flex; */
}

.sidebar ul li a {
  color: inherit;
  text-decoration: none;
}

.sidebar ul li img {
  width: var(--et-sideicon);
  margin-right: calc(var(--et-sideicon) - 1.5rem);
  padding: 0.35rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.sidebar ul li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  align-items: center;
  position: relative;
}

.sidebar ul li a .badge {
  position: absolute;
  right: 0.5rem;
  transform: scale(0.9);

  padding: 0.25rem 0.5rem;
  font-weight: 300;
  color: #000;
  background-color: white;
}

.sidebar ul li a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.side-main {
  margin-top: 9vh;
  padding-left: calc(var(--et-sidewidth) + 1rem);

  line-height: 1.2rem;
  font-size: 0.9rem;
  background: #eceff3;
}

.sidebar.hide {
  width: 0;
  opacity: 0;
}

.btnclose.hide {
  left: -1rem;
  transform: rotate(180deg);
}

.side-main.hide {
  padding-left: 2rem;
}

@media (max-width: 991px) {
  :root {
    --et-sidewidth: 45vw;
    --et-sideicon: 2rem;
  }

  .navbar-nav {
    width: 100%;
  }

  .side-main {
    margin-top: 10vh;
    padding-left: 2rem;
  }
}

@media (max-width: 456px) {
  :root {
    --et-sidewidth: 80vw;
    --et-sideicon: 2rem;
  }

  .side-main {
    margin-top: 12vh;
    padding-left: 2rem;
  }
}
</style>
