<template>
  <div>
    <div class="position-relative ">
      <div class="sidebar">
        <div class="brand">
          <span class="title"> e-Tender </span>
          <div class="small text-muted">by amtrex</div>
        </div>
        <div class="side-ul">
          <ul class=" m-0 p-0">
            <li>
              <a href="" class="active">
                <img src="@\assets\images\home.png" alt="">
                <div class="text-white">
                  Home
                </div>
              </a>
            </li>

            <li>
              <a href="/1_vendorview">
                <img src="@\assets\images\downloadfile.png" alt="">
                <div class="text-white">
                  Tenders
                  <span class="badge bg-et">4</span>
                </div>
              </a>
            </li>
            <li>
              <a href="">
                <img src="@\assets\images\listfile.png" alt="">
                <div class="text-white">
                  Tasks
                </div>
              </a>
            </li>
            <li>
              <a href="">
                <img src="@\assets\images\messager.png" alt="">
                <div class="text-white">
                  Auctions
                </div>
              </a>
            </li>

            <li>
              <a href="">
                <img src="@\assets\images\files.png" alt="">
                <div class="text-white">
                  Resources Library
                  <span class="badge bg-et">22</span>
                </div>
              </a>
            </li>
          </ul>
        </div>
        <div class="logout mt-auto ">
          <ul class="">
            <li class="">
              <a href="">
                <img src="@\assets\images\setting.png" alt="">
                <div class="text-white">
                  Settings
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <button class="btn btnclose" onclick="navControl()">
        <i class="fa-solid fa-angle-left"></i>
      </button>
    </div>
    <nav class="fixed-top navbar shadow-sm navbar-expand navbar-light bg-white">
      <div class="container-fluid m-0 p-0 ">
        <div class="collapse navbar-collapse d-flex align-items-end" id="navbarSupportedContent">
          <ul style="min-width: 75%" class="navbar-nav ms-auto mb-2 mb-lg-0 py-2">
            <li class="nav-item w-75">
              <form class="d-flex nav-search">
                <input style="font-weight: 300; border-radius: 0 ;" class="border-0 form-control py-2" type="search"
                  placeholder="Search" aria-label="Search">
                <button class="btn text-muted" type="submit">
                  search</button>
              </form>
            </li>
            <li class="nav-item ms-3 text-end d-flex">
              <div class="account-badge w-100">
                <div class="text-muted d-none d-md-block">
                  <span class="smallc " style="color:var(--nav-text-cvor);">
                    Welcome
                  </span>
                  <h6 class="my-0 small font-bold color-gy" style="color:var(--nav-text-cvor);"> Prasanth Raman </h6>
                </div>
                <div class="circle"><i class="far fa-user"></i></div>

              </div>
               downicon
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <main class="" style="background: #F0F4F7;">
      <slot />
    </main>
  </div>
</template>
<script>
export default {
  computed: {},
  methods: {},
};

</script>

<style>
:root {
  --et-bgbody: #ECEFF3;
  --et-sidebg: #040849;
  --et-sidewidth: 21vw;
  --et-accent: #645DF6;
  --et-sidehov: rgba(255, 255, 255, 0.12);
  --et-sideicon: 2rem;
}

:root {
  --et-bgbody: #ECEFF3;
  --et-sidebg: #040849;
  --et-sidewidth: 21vw;
  --et-accent: #645DF6;
  --et-sidehov: rgba(255, 255, 255, 0.12);
  --et-sideicon: 2rem;
}

.btn-outline-accent {
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;

  --bs-btn-color: var(--et-accent);
  --bs-btn-border-color: var(--et-accent);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--et-accent);
  --bs-btn-hover-border-color: var(--et-accent);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--et-accent);
  --bs-btn-active-border-color: var(--et-accent);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--et-accent);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--et-accent);
  --bs-gradient: none;
}

/* input:focus,
button:focus,
select:focus {
  box-shadow: none !important;
  border: 1px solid var(--et-accent) !important;
} */


.btn-accent {
  border-radius: 5px;
  padding: 0.5rem 1.5rem;
  font-size: 0.9rem;
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--et-accent);
  --bs-btn-border-color: var(--et-accent);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #453ce9;
  --bs-btn-hover-border-color: #453ce9;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #453ce9;
  --bs-btn-active-border-color: #453ce9;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--et-accent);
  --bs-btn-disabled-border-color: var(--et-accent);

}

.text-accent {
  color: var(--et-accent) !important;
}

.nav-search {
  transform: scale(0.85);
  border: 1px solid lightgray;
}

.nav-search .btn {
  border-radius: 0;
  border-left: 1px solid lightgray
}

.nav-search .btn:hover {
  box-shadow: none;
}

.account-badge {
  display: flex;
  justify-content: center;
  align-items: center;
}

.account-badge .circle {
  width: 2rem;
  margin-left: 1rem;
  outline: 1px solid green;
  outline-offset: 3px;
  height: 2rem;
  background-color: #D9531E;
  border-radius: 50%;
  display: grid;
  justify-content: center;
  align-items: center;
  color: white;
}

.sidebar {
  height: 100vh;
  z-index: 10000 !important;
  position: fixed;
  top: 0;
  background-color: var(--et-sidebg);
  width: var(--et-sidewidth);
  color: white;

  display: flex;
  flex-direction: column;
}

.btnclose {
  position: absolute;
  top: 1.5rem;
  border-radius: 50%;
  height: 2.5rem;
  z-index: 10000 !important;
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--et-accent);
  left: calc(var(--et-sidewidth) - 1.25rem);
  border: 5px solid var(--et-bgbody);
  color: white;
}

.btnclose:hover,
.btnclose:focus {
  background-color: var(--et-sidebg);
  border: 5px solid var(--et-bgbody);
  color: white;
}


.sidebar .brand {
  margin: 0.5rem;
  padding: 0.25rem;
  padding-bottom: 1.5rem;
  text-align: center;
  border-radius: 10px;
  background-color: var(--et-sidehov);

}

.sidebar .brand .title {
  background-color: var(--et-sidehov);

  /* Create the gradient. */
  background-image: linear-gradient(#ffffff, #ffffff, #040749);

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;

  font-weight: 700;
  font-size: 2rem;
  /* line-height: 2.5rem; */
}

.sidebar .brand .small {
  line-height: 0rem;
}

.sidebar .side-ul {
  margin-top: 4rem;
}

.sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar ul li {
  /* display: flex; */
}

.sidebar ul li a {
  color: inherit;
  text-decoration: none;
}

.sidebar ul li img {
  width: var(--et-sideicon);
  margin-right: calc(var(--et-sideicon) - 1.5rem);
  padding: 0.35rem;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
}

.sidebar ul li a {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0.25rem 0rem;
  padding: 0.5rem;
  font-size: 0.9rem;
  align-items: center;
  position: relative;
}

.sidebar ul li a .badge {
  position: absolute;
  right: 0.5rem;
  transform: scale(0.9);

  padding: 0.25rem 0.5rem;
  font-weight: 300;
  color: #000;
  background-color: white;

}

.sidebar ul li a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.side-main {
  margin-top: 9vh;
  padding-left: calc(var(--et-sidewidth) + 1rem);

  line-height: 1.2rem;
  font-size: 0.9rem;
  background: #eceff3;
}

.sidebar.hide {
  width: 0;
  opacity: 0;
}

.btnclose.hide {
  left: -1rem;
  transform: rotate(180deg);
}

.side-main.hide {
  padding-left: 2rem;
}

@media (max-width: 991px) {
  :root {
    --et-sidewidth: 45vw;
    --et-sideicon: 2rem;
  }

  .navbar-nav {
    width: 100%;
  }

  .side-main {
    margin-top: 10vh;
    padding-left: 2rem;
  }
}

@media (max-width: 456px) {
  :root {
    --et-sidewidth: 80vw;
    --et-sideicon: 2rem;
  }

  .side-main {
    margin-top: 12vh;
    padding-left: 2rem;
  }
}
</style>
