let data = [
  {
    path: "/admin/master",
    component: () => import("@/pages/Admin/Master/List.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  {
    path: "/admin/masterimport",
    component: () => import("@/pages/Admin/Master/ImportList.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },

  {
    path: "/admin/master/resourceLibrary",
    name: "admin_master_resourceLibrary",
    component: () =>
      import("@/pages/Admin/Master/ResourceLibrary/resourcelibrary.vue"),
    meta: {
      layout: "home",
    },
  },
  {
    name: "admin_master_productservice",
    path: "/admin/master/productservice",
    component: () => import("@/pages/Admin/Master/ProductService/Manage.vue"),
    meta: {
      layout: "home",
      title: "productservice",
    },
  },
  {
    path: "/admin/master/projectlocation",
    name: "admin_master_projectlocations",
    component: () => import("@/pages/Admin/Master/ProjectLocations/Manage.vue"),
    meta: {
      layout: "home",
      title: "projectlocation",
    },
  },
  // Country////
  {
    name: "admin_master_country_add",
    path: "/admin/master/country/add",
    component: () => import("@/pages/Admin/Master/Country/Manage.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  {
    name: "admin_master_country_edit",
    path: "/admin/master/country/edit/:id",
    component: () => import("@/pages/Admin/Master/Country/Manage.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  {
    name: "admin_master_country",
    path: "/admin/master/country",
    component: () => import("@/pages/Admin/Master/Country/List.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  //  Industry//////
  {
    name: "admin_master_industries_add",
    path: "/admin/master/industries/add",
    component: () => import("@/pages/Admin/Master/Industries/Manage.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  {
    name: "admin_master_industries_edit",
    path: "/admin/master/industries/edit/:id",
    component: () => import("@/pages/Admin/Master/Industries/Manage.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  {
    name: "admin_master_industries",
    path: "/admin/master/industries",
    component: () => import("@/pages/Admin/Master/Industries/List.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  ////Sectors/////
  {
    name: "admin_master_sectors_add",
    path: "/admin/master/sectors/add",
    component: () => import("@/pages/Admin/Master/Sectors/Manage.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  {
    name: "admin_master_sectors_edit",
    path: "/admin/master/sectors/edit/:id",
    component: () => import("@/pages/Admin/Master/Sectors/Manage.vue"),
    meta: {
      layout: "home",
      title: "Manage",
    },
  },
  {
    name: "admin_master_sectors",
    path: "/admin/master/sectors",
    component: () => import("@/pages/Admin/Master/Sectors/List.vue"),
    meta: {
      layout: "home",
      title: "Manage",
      middleware: "admin",
    },
  },
];

export default data;
