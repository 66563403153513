import axios from "@/plugins/axios";
class AuthService {
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("expdate");
  }
  signin(data) {
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("token", JSON.stringify(data.token || null));
    localStorage.setItem("expdate", JSON.stringify(data.expdate || null));
  }
  login(type, data) {
    return axios.post("auth/signin" + "/" + type, data);
  }
  register(type, data) {
    return axios.post("auth/signup" + "/" + type, data);
  }
  getregistercompanydet() {
    return axios.get("auth/register/companydetails");
  }
  getcheckpage() {
    return axios.get("auth/register/checkpage");
  }
  getbuisnessinfodet() {
    return axios.get("auth/register/buisnessinfo");
  }
  getaddresscontdet() {
    return axios.get("auth/register/addresscontacts");
  }
  getexperiencedet() {
    return axios.get("auth/register/experience");
  }

  registerpost(url, data) {
    return axios.post("auth/register" + "/" + url, data);
  }
  deleteregisterpost(url, data) {
    return axios.delete("auth/register" + "/" + url + "/" + data);
  }

  deletesuibdetpost(url, data) {
    return axios.post("auth/delete" + "/" + url, data);
  }
  getreview() {
    return axios.get("auth/register/buyer/review");
  }
  getvendorreview() {
    return axios.get("auth/register/vendor/review");
  }

  newtenderpost(url, data) {
    return axios.post("auth/newtender/creation" + "/" + url, data);
  }
  getnewtenderdetails() {
    return axios.get("auth/newtender/details");
  }
}

export default new AuthService();
